/*******************************************************************************
 * Global Variables
 ******************************************************************************/
:root {
  /* COLORS */
  --colors-primary-white: #ffffff;
  --colors-primary-jade: #328c92;
  --colors-primary-glacier: #ebf5f6;
  --colors-primary-pearl: #f2f7f8;
  --colors-primary-slate: #282828;
  --colors-primary-nickel: #727272;
  --colors-primary-mist: #d9d9d9;

  --colors-secondary-cream: #fff7ce;
  --colors-secondary-magnolia: #fff9e5;
  --colors-secondary-snow: #fcfcfc;

  --colors-special-pass: #64bf30;
  --colors-special-warn: #bf9830;
  --colors-special-fail: #bf3d30;
  --colors-special-info: #30b2bf;

  /* shadows */
  --shadow-main: 0px 2px 40px rgba(0, 0, 0, 0.1);
  --shadow-harsh: 0px 2px 25px rgba(0, 0, 0, 0.25);
  --shadow-glow: 0px -0.309px 12.855px rgba(84, 190, 197, 0.6);

  /* PADDING */
  --padding-global: clamp(1rem, 5vw, 2rem);
  --padding-column-gap: 2.4rem;

  /* FONTS */
  --font-size-smaller: clamp(calc(1.6rem * 0.85), 1.6vw, 1.6rem);
  --font-size-small: clamp(calc(2rem * 0.8), 2vw, 2rem);
  --font-size-normal: clamp(calc(2.4rem * 0.75), 2.4vw, 2.4rem);
  --font-size-large: clamp(calc(3.2rem * 0.7), 3.2vw, 3.2rem);
  --font-size-larger: clamp(calc(3.6rem * 0.65), 3.6vw, 3.6rem);
  --font-size-huge: clamp(calc(4.2rem * 0.65), 4.2vw, 4.2rem);

  --font-family-headline: "Fira Sans", helvetica, sans-serif;
  --font-family-body: "Fira Sans", helvetica, sans-serif;

  /* WIDTHS */
  --centered-content-max-width: 70rem;
  --width-site-max: 124rem;
}

@media screen and (min-width: 800px) {
  :root {
    --padding-global: clamp(2.5rem, 5vw, 5rem);
  }
}

/*******************************************************************************
 * Resets and Global Defaults
 ******************************************************************************/

::selection {
  background: var(--colors-secondary-magnolia);
  color: var(--colors-primary-jade);
}

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
fieldset,
blockquote,
dl,
dd,
ul,
ol {
  margin: 0;
  padding: 0;
}

small {
  font-size: inherit;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  margin-left: 0;
  list-style: none;
}

/* Set core root defaults */
html {
  scroll-behavior: smooth;
  font-size: 62.5%;
  background: var(--colors-primary-white);
  min-height: 100vh;
}

/* Set core body defaults */
body {
  text-rendering: optimizeSpeed;
  font-family: var(--font-family-body);
  font-size: var(--font-size-normal);
  color: var(--colors-primary-slate);
  font-weight: 200;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
  -webkit-appearance: none;
}

fieldset {
  border: 0;
}

img {
  width: 100%;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  font-family: var(--font-family-headline);
  line-height: 1;
}

h1,
.h1 {
  font-size: 4.8rem;
  font-weight: 300;
}

h2,
.h2 {
  font-size: 2.4rem;
  font-weight: 400;
}

h3,
.h3 {
  font-size: 2rem;
  font-weight: 400;
}

h4,
.h4 {
  color: var(--colors-primary-jade);
  font-size: 1.6rem;
  font-weight: 800;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}

.body-text {
  font-size: 2rem;
  font-weight: 300;
}

.body-text-small {
  font-size: 1.6rem;
  font-weight: 300;
}

.small-print {
  font-size: 1.2rem;
  font-weight: 300;
}

.input-container {
  display: flex;
  border-radius: 0.8rem;
  border: 0.1rem solid var(--colors-primary-mist);
  padding: 0.8rem;
  align-items: center;
  gap: 0.8rem;
}

.input-container:focus-within {
  border: 0.1rem solid var(--colors-primary-jade);
}

.input-container input {
  border: none;
  outline: none;
  width: 100%;
}

.cta-primary {
  position: relative;
  background: radial-gradient(
    50.3% 50.96% at 50% 100.28%,
    #349da3 0%,
    #54bec5 100%
  );
  border-radius: 0.4rem;
  cursor: pointer;
  padding: 1.2rem 0.8rem;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.15);
  color: var(--colors-primary-white);
  text-decoration: none;
  border: 1px solid rgba(89, 195, 255, 0.42);
  font-weight: 600;
  display: inline-flex;
  font-size: 1.6rem;
  transition: box-shadow 0.5s ease-in-out, top 0.1s ease-out;
  box-shadow: 0 0 0 var(--colors-primary-jade);
  top: 0;
  min-width: 14rem;
  align-items: center;
  justify-content: center;
}

.cta-primary[disabled] {
  opacity: 0.5;
}

.input-row {
  display: flex;
  gap: 1.6rem;
}

.institution-select {
  flex: 1;
}

.input-row .cta-primary {
  padding-top: 0;
  padding-bottom: 0;
}

.cta-primary:hover {
  box-shadow: var(--shadow-glow);
}

.cta-primary:active {
  top: 0.1rem;
  box-shadow: 0 0 1rem var(--colors-primary-jade);
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

.standard-text-block {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}

/*******************************************************************************
 * Header Styles
 ******************************************************************************/

.global-header {
  position: fixed;
  z-index: 2;
  width: 100%;
  transition: all 0.2s ease-in-out;
}

.global-header.scrolled {
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(0.5rem);
  box-shadow: 0 1rem 5rem rgba(0, 0, 0, 0.1);
}

.global-header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem var(--padding-global);
}

.global-header-logo {
  width: clamp(6rem, 8vw, 8rem);
}

.global-header-nav-list {
  display: flex;
  gap: var(--padding-column-gap);
  align-items: baseline;
}

.global-header-nav-list-item-link {
  font-size: var(--font-size-smaller);
  text-decoration: none;
  color: var(--colors-primary-slate);
  transition: border 0.2s ease-in-out;
  border-bottom: 1px solid transparent;
}

.global-header-nav-list-item-link:hover {
  border-bottom: 1px solid var(--colors-primary-slate);
}

@media (max-width: 650px) {
  .global-header-inner {
    padding: var(--padding-global);
  }

  .global-header-nav-list-item-link {
    font-size: var(--font-size-small);
  }
}

/*******************************************************************************
 * Hero Styles
 ******************************************************************************/

.hero-wrapper {
  background-color: var(--colors-secondary-magnolia);
  background-image: url("/images/mandala-hero.svg");
  background-repeat: no-repeat;
  background-position: right -100px top -100px;
  display: flex;
  justify-content: center;
  min-height: 618px;
  padding-top: 10rem;
  text-align: center;
  position: relative;
}

.hero-image {
  width: auto;
  max-width: 48rem;
  display: none;
}

@media (min-width: 950px) {
  .hero-image {
    display: block;
  }
}

.hero-wrapper-inner {
  color: var(--colors-primary-jade);
  padding: 12rem var(--padding-global) 0 var(--padding-global);
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}

.hero-wrapper-inner h4 {
  color: var(--colors-primary-nickel);
}

.hero-wrapper-inner h1 {
  line-height: 1.2;
}

.hero-wrapper-cta-container {
  margin-top: 1.6rem;
}

@media (max-width: 800px) {
  .hero-wrapper {
    min-height: auto;
    padding-top: 0;
    padding-bottom: var(--padding-global);
  }

  .hero-wrapper h1 {
    font-size: 3.6rem;
  }
}

/*******************************************************************************
 * Calculator Styles
 ******************************************************************************/
.hero-calculator-wrapper {
  position: relative;
}

.calculator-wrapper {
  text-align: left;
  display: flex;
  padding: 3.2rem;
  background: var(--colors-primary-white);
  box-shadow: 0 0.2rem 4rem 0 rgba(0, 0, 0, 0.1);
  flex-direction: column;
  align-items: stretch;
  gap: 1.6rem;
  margin: auto;
}

@media screen and (min-width: 800px) {
  .calculator-wrapper {
    position: absolute;
    left: 50%;
    bottom: 0%;
    border-radius: 1rem;
    width: 100%;
    max-width: 70rem;
    transform: translate(-50%, 75%);
    z-index: 1;
  }
}

@media screen and (min-width: 1200px) {
  .calculator-wrapper {
    transform: translate(-50%, 50%);
  }
}

.calculator-wrapper form {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.calculator-loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;
  text-align: center;
}

.institution-icon {
  margin-top: 0.6rem;
  margin-left: 0.8rem;
  width: 2rem;
  flex-shrink: 0;
  align-self: start;
}

.institution-icon-fallback {
  width: 100%;
}

.selected-institution-row {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.selected-institution {
  font-size: 2.8rem;
  font-weight: 400;
}

.calculator-disclaimer {
  position: absolute;
  left: 0;
  top: calc(100% + 1.6rem);
  width: 100%;
  font-size: 1.2rem;
  padding: 0 var(--padding-global);
}

@media screen and (min-width: 800px) {
  .calculator-disclaimer {
    padding: 0;
  }
}

.calculator-results {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.6rem 0;
  flex-wrap: wrap;
}

.calculator-result {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1.6rem;
}

.calculator-result-title {
  color: var(--colors-primary-nickel);
  font-size: 1.2rem;
  font-weight: 800;
  text-transform: uppercase;
}

.calculator-result-value {
  font-size: 3.2rem;
  font-weight: 300;
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

@media screen and (max-width: 600px) {
  .calculator-results {
    flex-direction: column;
    gap: 3rem;
  }

  .calculator-result-title {
    font-size: 1.6rem;
  }
}

.button-wrapper {
  margin-top: 3.2rem;
}

/*******************************************************************************
 * Feature Lists Styles
 ******************************************************************************/

.feature-list-wrapper {
  padding: var(--padding-global);
  position: relative;
}

.feature-list-wrapper .standard-text-block {
  max-width: var(--centered-content-max-width);
  margin: 0 auto;
  text-align: center;
}

.feature-list {
  display: flex;
}

ul.carousel-container {
  border-radius: 1rem;
  background: var(--colors-primary-white);
  max-width: var(--width-site-max);
  margin: 0 auto;
}

.feature-list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1.2rem;
  border-bottom: 0.1rem solid var(--colors-primary-mist);
  flex: 1;
  padding: 0 1.6rem;
}

.feature-list-item:last-child {
  border-bottom: none;
}

@media screen and (min-width: 650px) {
  .carousel-container {
    box-shadow: var(--shadow-main);
    padding: var(--padding-global);
  }

  .feature-list-item {
    border-bottom: none;
    border-left: 0.1rem solid var(--colors-primary-mist);
  }

  .feature-list-item:first-child {
    border-left: none;
  }
}

.feature-list-item-title {
  font-weight: 700;
  font-size: var(--font-size-large);
}

.feature-list-item-graphic-wrapper {
  width: 6.2rem;
}

.feature-list-item-title-stat {
  font-size: 3.6rem;
  font-weight: 700;
  display: block;
}

.feature-list-item-title {
  font-weight: 700;
  font-size: 2.4rem;
}

.feature-list-item-subtitle {
  font-size: 1.6rem;
  font-weight: 300;
}

@media (max-width: 650px) {
  .feature-list {
    flex-direction: column;
  }

  .feature-list-item {
    padding: 1.6rem 0;
    border: 0;
  }
}

/*******************************************************************************
 * Dual Columns Styles
 ******************************************************************************/

.dual-columns-wrapper {
  background-color: var(--colors-secondary-snow);
}

.dual-columns-wrapper-inner {
  display: flex;
  padding: 0 var(--padding-global);
  gap: 5.6rem;
  align-items: end;
  max-width: var(--width-site-max);
  margin: 0 auto;
}

.dual-columns-column-inner {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  font-size: clamp(1.5rem, 2vw, 2rem);
}

.dual-columns-right-wrapper-inner {
  padding: var(--padding-global) 0;
}

.dual-columns-wrapper-title-left .dual-columns-column-title {
  padding-top: 1.6rem;
}

.dual-columns-wrapper-title-right .dual-columns-column-title {
  padding-top: 1.6rem;
}

.dual-columns-wrapper-title-left .dual-columns-wrapper-inner {
  align-items: start;
}

@media (max-width: 650px) {
  .dual-columns-wrapper-inner {
    padding: calc(var(--padding-global) * 2) var(--padding-global);
    flex-direction: column;
  }

  .dual-columns-wrapper-title-left .dual-columns-wrapper-inner {
    gap: 1.6rem;
  }
}

/*******************************************************************************
 * Proven Product Styles
 ******************************************************************************/

.proven-product-wrapper {
  text-align: center;
  background-color: var(--colors-primary-pearl);
  background-image: url("/images/mandala-proven-product.svg");
  background-repeat: no-repeat;
  background-position: left 50% bottom -50%;
  padding: calc(var(--padding-global) * 2) var(--padding-global);

  margin: 0 auto;
  min-height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.proven-product-wrapper-inner {
  max-width: 95rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.proven-product-wrapper .feature-list-wrapper {
  padding: 0 var(--padding-global);
  margin: 5rem 0;
}

@media (max-width: 650px) {
  .proven-product-wrapper .feature-list-wrapper {
    margin: 2.5rem 0;
  }
}

/*******************************************************************************
 * Testimonials Styles
 ******************************************************************************/

.testimonial-wrapper {
  background-color: #fff;
  background-image: url("/images/quote.svg");
  background-repeat: no-repeat;
  background-position: right 25% top 50px;
  padding: calc(var(--padding-global) * 2) var(--padding-global);
  max-width: var(--width-site-max);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.testimonial-wrapper-inner {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  max-width: var(--centered-content-max-width);
}

.testimonial-wrapper-inner blockquote {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  font-style: italic;
}

.testimonial-wrapper-inner cite {
  font-size: var(--font-size-small);
}

/*******************************************************************************
 * Process Styles
 ******************************************************************************/

.process-bullet-points {
  --bullet-size: 20px;

  list-style: none;
  padding: 0;
  margin: 1.6rem 0 1.6rem 0.4rem;
}

.process-bullet-points li {
  position: relative;
  padding-left: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.process-bullet-points li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0.4rem;
  width: var(--bullet-size);
  height: var(--bullet-size);
  border-radius: 50%;
  background: var(--colors-primary-jade);
  border: 4px solid var(--colors-primary-white);
  box-shadow: 0 0 0 1px var(--colors-primary-jade);
  z-index: 1;
}

.process-bullet-points li:after {
  content: "";
  position: absolute;
  left: 9px;
  top: 12px;
  width: 2px;
  height: 100%;
  background: var(--colors-primary-jade);
}

.process-bullet-points li:last-child:after {
  display: none;
}

/*******************************************************************************
 * Footer Styles
 ******************************************************************************/

.global-footer {
  display: flex;
  background-color: var(--colors-primary-pearl);
  background-image: url("/images/mandala-footer.svg");
  background-size: 870px 870px;
  background-position: right -370px top -47px;
  background-repeat: no-repeat;
  font-size: 12rem;
  padding: 12.8rem 5rem;
}

.global-footer-nav-list-item-link {
  font-size: var(--font-size-smaller);
  text-decoration: none;
  color: var(--colors-primary-slate);
  transition: border 0.2s ease-in-out;
  border-bottom: 1px solid transparent;
}

.global-footer-nav-list-item-link:hover {
  border-bottom: 1px solid var(--colors-primary-slate);
}

.global-footer-inner {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  flex-direction: column;
  gap: 4.3rem;
  max-width: var(--width-site-max);
  margin: 0 auto;
  padding: 0 var(--padding-global);
}

.global-footer-columns {
  display: inline-flex;
  gap: 4.1rem;
}

.global-footer-column {
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.global-footer-column-title {
  font-weight: 400;
  margin: 0;
  text-transform: uppercase;
}

ul.global-footer-list {
  list-style: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.global-footer-address {
  font-style: normal;
}

.copyright {
  font-size: 1.2rem;
}

@media (max-width: 500px) {
  .global-footer {
    flex-direction: column-reverse;
  }

  .global-footer-inner {
    margin: 0;
  }
}


/**
 * These are special styles just for plain default HTML pages
 */
.default .inner-wrapper {
  margin: 0 auto;
  padding: 13rem var(--padding-global);
  max-width: 90rem;
  font-size: var(--font-size-small);
}

.default .inner-wrapper ul, .default .inner-wrapper ol {
  padding-left: 2rem;
}

.default .inner-wrapper > * + *, .default .inner-wrapper li {
  margin-top: 2.4rem;
}

.default .inner-wrapper h3 {
  font-size: var(--font-size-normal)
}

.default .inner-wrapper li h3 {
  font-size: var(--font-size-small)
}

.default .inner-wrapper li ul, .default .inner-wrapper li ol {
  padding-left: 1.6rem;
}

.default .inner-wrapper strong {
  font-weight: 400;
}